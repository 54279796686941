import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  VStack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { clientAPI } from "../../api/client";
import toast from "react-hot-toast";
import { useTab } from "../../contexts/useTab";

const SignIn = () => {
  const { signIn, setsignIn } = useTab();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    const accessToken = localStorage.getItem('access_token');
    if(accessToken){
      setIsLoading(false);
      setsignIn(true);
    }
  },[])

  const handleSignIn = async (data) => {
    try {
      setIsLoading(true);
      const dataAdmin = await clientAPI("post", "/api/taskAirdrop/checkAdmin", {
        wallet_address: data.address,
        password: data.password,
      });
      localStorage.setItem('access_token', dataAdmin.accessToken);

      reset();
    } catch (error) {
      console.error("Error during sign-in:", error);
    } finally {
      setIsLoading(false);
      setsignIn(true);
    }
  };

  return (
    <>
      <Box
        display={signIn ? "none" : "block"}
        w="500px"
        mx="auto"
        mt={10}
        p={5}
        shadow="md"
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor="var(--color-background-popup)"
        zIndex="1000"
      >
        <Heading textAlign="center" mb={6}>
          Login Admin
        </Heading>
        <form onSubmit={handleSubmit(handleSignIn)}>
          <VStack spacing={4}>
            <FormControl isInvalid={errors.address}>
              <FormLabel>Address</FormLabel>
              <Input
                placeholder="Enter your address"
                {...register("address", { required: "Address is required" })}
              />
              {errors.address && (
                <Text color="red">{errors.address.message}</Text>
              )}
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <Text color="red">{errors.password.message}</Text>
              )}
            </FormControl>
            <Button
              type="submit"
              width="full"
              backgroundColor="var(--color-main)"
              color="#FFF"
            >
              Log In
            </Button>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default SignIn;
