const contract = {
	testnet: {
		CONTRACT_ADDRESS: "0xC43ddE40dFA8109f9169B3f2C2d2f33543391245",
		CONTRACT_ABI: [
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_bachiTokenAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_taikoTokenAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_backendSigner",
				type: "address",
			  },
			],
			stateMutability: "nonpayable",
			type: "constructor",
		  },
		  {
			inputs: [],
			name: "AccessControlBadConfirmation",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				internalType: "bytes32",
				name: "neededRole",
				type: "bytes32",
			  },
			],
			name: "AccessControlUnauthorizedAccount",
			type: "error",
		  },
		  {
			inputs: [],
			name: "EnforcedPause",
			type: "error",
		  },
		  {
			inputs: [],
			name: "ExpectedPause",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "owner",
				type: "address",
			  },
			],
			name: "OwnableInvalidOwner",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "OwnableUnauthorizedAccount",
			type: "error",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			  },
			],
			name: "Deposited",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "FundsWithdrawn",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "mode",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "lastIncreaseSign",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			],
			name: "HistoryWithdraws",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "OwnershipTransferred",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "Paused",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "bytes32",
				name: "previousAdminRole",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "bytes32",
				name: "newAdminRole",
				type: "bytes32",
			  },
			],
			name: "RoleAdminChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			  },
			],
			name: "RoleGranted",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			  },
			],
			name: "RoleRevoked",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "Unpaused",
			type: "event",
		  },
		  {
			inputs: [],
			name: "ADMIN_ROLE",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "DEFAULT_ADMIN_ROLE",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "bachiToken",
			outputs: [
			  {
				internalType: "contract IERC20",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "backendSigner",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			  },
			],
			name: "deposit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_addressHistoryWithdraw",
				type: "address",
			  },
			],
			name: "getCountHistoryWithdraws",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "wallet",
				type: "address",
			  },
			],
			name: "getLastNumber",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			],
			name: "getRoleAdmin",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "grantRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "hasRole",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			name: "historyWithdraws",
			outputs: [
			  {
				internalType: "uint8",
				name: "mode",
				type: "uint8",
			  },
			  {
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			  {
				internalType: "uint256",
				name: "lastIncreaseSign",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "owner",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "pause",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "paused",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "renounceOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "callerConfirmation",
				type: "address",
			  },
			],
			name: "renounceRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "revokeRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_bachiTokenAddress",
				type: "address",
			  },
			],
			name: "setBachiTokenAddress",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_backendSigner",
				type: "address",
			  },
			],
			name: "setBackendSigner",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_taikoTokenAddress",
				type: "address",
			  },
			],
			name: "setTaikoTokenAddress",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes4",
				name: "interfaceId",
				type: "bytes4",
			  },
			],
			name: "supportsInterface",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "taikoToken",
			outputs: [
			  {
				internalType: "contract IERC20",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "transferOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "unpause",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "withdrawBachi",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "withdrawTaiko",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "numberSign",
				type: "uint256",
			  },
			  {
				internalType: "uint8",
				name: "mode",
				type: "uint8",
			  },
			  {
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			  {
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			],
			name: "withdrawUser",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		],
	  },
	  mainnet: {
		CONTRACT_ADDRESS: "0xB6d793A9413a8B9cE7a7Fe8c21C6E1249F578AD8",
		CONTRACT_ABI: [
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_bachiTokenAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_taikoTokenAddress",
				type: "address",
			  },
			  {
				internalType: "address",
				name: "_backendSigner",
				type: "address",
			  },
			],
			stateMutability: "nonpayable",
			type: "constructor",
		  },
		  {
			inputs: [],
			name: "AccessControlBadConfirmation",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				internalType: "bytes32",
				name: "neededRole",
				type: "bytes32",
			  },
			],
			name: "AccessControlUnauthorizedAccount",
			type: "error",
		  },
		  {
			inputs: [],
			name: "EnforcedPause",
			type: "error",
		  },
		  {
			inputs: [],
			name: "ExpectedPause",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "owner",
				type: "address",
			  },
			],
			name: "OwnableInvalidOwner",
			type: "error",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "OwnableUnauthorizedAccount",
			type: "error",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "user",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			  },
			],
			name: "Deposited",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "FundsWithdrawn",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "mode",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "lastIncreaseSign",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				indexed: false,
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			],
			name: "HistoryWithdraws",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "OwnershipTransferred",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "Paused",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "bytes32",
				name: "previousAdminRole",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "bytes32",
				name: "newAdminRole",
				type: "bytes32",
			  },
			],
			name: "RoleAdminChanged",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			  },
			],
			name: "RoleGranted",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			  {
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			  },
			],
			name: "RoleRevoked",
			type: "event",
		  },
		  {
			anonymous: false,
			inputs: [
			  {
				indexed: false,
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "Unpaused",
			type: "event",
		  },
		  {
			inputs: [],
			name: "ADMIN_ROLE",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "DEFAULT_ADMIN_ROLE",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "bachiToken",
			outputs: [
			  {
				internalType: "contract IERC20",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "backendSigner",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			  },
			],
			name: "deposit",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_addressHistoryWithdraw",
				type: "address",
			  },
			],
			name: "getCountHistoryWithdraws",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "wallet",
				type: "address",
			  },
			],
			name: "getLastNumber",
			outputs: [
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			],
			name: "getRoleAdmin",
			outputs: [
			  {
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "grantRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "hasRole",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "",
				type: "uint256",
			  },
			],
			name: "historyWithdraws",
			outputs: [
			  {
				internalType: "uint8",
				name: "mode",
				type: "uint8",
			  },
			  {
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			  {
				internalType: "uint256",
				name: "lastIncreaseSign",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "owner",
			outputs: [
			  {
				internalType: "address",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "pause",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "paused",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "renounceOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "callerConfirmation",
				type: "address",
			  },
			],
			name: "renounceRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			  },
			  {
				internalType: "address",
				name: "account",
				type: "address",
			  },
			],
			name: "revokeRole",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_bachiTokenAddress",
				type: "address",
			  },
			],
			name: "setBachiTokenAddress",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_backendSigner",
				type: "address",
			  },
			],
			name: "setBackendSigner",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "_taikoTokenAddress",
				type: "address",
			  },
			],
			name: "setTaikoTokenAddress",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "bytes4",
				name: "interfaceId",
				type: "bytes4",
			  },
			],
			name: "supportsInterface",
			outputs: [
			  {
				internalType: "bool",
				name: "",
				type: "bool",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [],
			name: "taikoToken",
			outputs: [
			  {
				internalType: "contract IERC20",
				name: "",
				type: "address",
			  },
			],
			stateMutability: "view",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "newOwner",
				type: "address",
			  },
			],
			name: "transferOwnership",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [],
			name: "unpause",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "withdrawBachi",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "address",
				name: "to",
				type: "address",
			  },
			  {
				internalType: "uint256",
				name: "value",
				type: "uint256",
			  },
			],
			name: "withdrawTaiko",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		  {
			inputs: [
			  {
				internalType: "uint256",
				name: "numberSign",
				type: "uint256",
			  },
			  {
				internalType: "uint8",
				name: "mode",
				type: "uint8",
			  },
			  {
				internalType: "uint256",
				name: "bachiAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "taikoAmount",
				type: "uint256",
			  },
			  {
				internalType: "uint256",
				name: "timestamp",
				type: "uint256",
			  },
			  {
				internalType: "bytes",
				name: "signature",
				type: "bytes",
			  },
			],
			name: "withdrawUser",
			outputs: [],
			stateMutability: "nonpayable",
			type: "function",
		  },
		],
	  },
};

const withdraw_farmer_contract =
  contract[process.env.REACT_APP_ENV || "testnet"];
export default withdraw_farmer_contract;
