import { http, createConfig, createStorage } from "wagmi";
import { holesky, taikoHekla, mainnet, taiko } from "wagmi/chains";
import { injected, metaMask } from "wagmi/connectors";

export const config = createConfig({
  autoConnect: true,
  chains: [taiko, taikoHekla],
  connectors: [injected(), metaMask()],
  // storage: createStorage({ storage: window.localStorage }),
  transports: {
    [taiko.id]: http(),
    [taikoHekla.id]: http(),
  },
});
